import * as React from 'react';
import useFetch from 'react-fetch-hook';
import './Home.css';
import { GoSearch } from 'react-icons/go'
import { Shopper } from '../components/Shopper';

const ChampSelectTitleSearch = (props) => {
    const [search, setSearch] = React.useState(props.value);

    const handleChange = (event) => {
        setSearch(event.target.value);
        props.onChange(event.target.value);
    }

    return (
        <div className='champion-selector-title'>
            <a href='/' style={{ textDecoration: 'none', color: 'black' }}>League Shopper</a>
            <div className='champion-selector-search'>
                <GoSearch color='gray' style={{ background: '#eee', height: '20px', paddingTop: 5, paddingBottom: 5, paddingLeft: 3, borderTopLeftRadius: '5em', borderBottomLeftRadius: '5em' }} />
                <input value={search} onChange={handleChange} />
            </div>
        </div>
    )
}

export const Home = () => {
    const { isLoading, error, data } = useFetch('http://ddragon.leagueoflegends.com/cdn/12.2.1/data/en_US/champion.json');
    const [search, setSearch] = React.useState('');
    const [selected, setSelected] = React.useState(null);

    if (isLoading) return 'Loading...';
    if (error) return 'Error!';

    const rgx = str => {
        var rtrn = '';
        for (var char of str)
            rtrn += `${char}.*`;
        return rtrn;
    }

    const createChampionGrid = data => {
        let championComponents = [];
        for (let champion in data) {
            let id = data[champion].id;
            if (id === 'Fiddlesticks') id = 'FiddleSticks';
            championComponents.push(
                <button
                    onClick={() => {
                        setSelected(data[champion]);
                    }}
                    key={data[champion].name}
                    className='champion-tile-img'
                    style={{ backgroundImage: `url("https://ddragon.leagueoflegends.com/cdn/img/champion/tiles/${id}_0.jpg")` }}
                    alt={data[champion].name}
                />
            )
        }
        return championComponents;
    }


    return (
        <>
            <ChampSelectTitleSearch value={search} onChange={setSearch} />
            <div className='champion-selector-grid'>
                {createChampionGrid(Object.fromEntries(Object.entries(data.data)
                    .sort(([, a], [, b]) => ('' + a.name).localeCompare(b.name))))
                    .filter(element => !!element.key.toLowerCase().match(rgx(search.toLowerCase())))}
            </div>
            <Shopper visible={!!selected} data={selected} onClick={() => setSelected(null)} />
        </>
    )
}

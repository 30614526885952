import React from 'react';
import './Shopper.css';

export const Shopper = (props) => {

    const createCard = (data) => {
        let components = [];
        for (let stat in data.stats) {
            components.push(
                <div key={stat} className='key-value-pair'>
                    <div className='key'><b>{stat}</b></div>
                    <div className='value'>{data.stats[stat]}</div>
                </div>
            );
        }
        return components;
    }


    if (props.visible) {
        let id = props.data.id;
        if (id === 'Fiddlesticks') id = 'FiddleSticks';
        return (
            <>
                <div className='shopper-back' onClick={props.onClick}></div>
                <div className='shopper-wrapper'>
                    <div className='shopper-title'>
                        <img
                            className='shopper-title-img'
                            style={{ width: '50px', height: '50px', margin: 0, marginRight: 20 }}
                            src={`https://ddragon.leagueoflegends.com/cdn/img/champion/tiles/${id}_0.jpg`}
                            alt=''
                        />
                        <h1>{props.data.name}</h1>
                    </div>
                    {createCard(props.data)}
                </div>
            </>
        )
    }
    else return <></>;
}
